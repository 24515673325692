import { notifyBugSnag } from "@connect/BugSnag";
import { history, store } from "@connect/Data";
import { envSwitch } from "@connect/Env";
import { Utils } from "@connect/Utils";
import { errorNotification } from "Data/Actions/Notifications";
import { setConnectedStatus } from "Data/Actions/System";
import { logoutUser } from "Data/Actions/User";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { VERSION } from "Data/Objects/AppState";

const host = envSwitch<string>(
	"https://api.clintonconnect.com",
	"https://staging.api.clintonconnect.com",
	"https://development.api.clintonconnect.com",
	"http://connect-api.test"
);
const baseUrl = host + "/v1";
const companyUrl = (path) => baseUrl + "/company/" + getActiveCompanyId(store.getState()) + path;
const adminUrl = (path) => baseUrl + path;
const getBaseUrl = (path, admin) => {
	if (admin) {
		return adminUrl(path);
	}
	return companyUrl(path);
}

const responseHasError = (response: Response) => {
	return new Promise((resolve, reject) => {
		if (!response.ok) {
			response.json()
				.then((error) => {
					notifyBugSnag(new Error(error));
					reject(error);
				})
				.catch((error) => {
					reject(error);
				});
		} else {
			resolve(response);
		}
	})
}

const sessionIsValid = (response: Response) => {
	const { User } = store.getState();

	if (response.status === 401 && history.location.pathname !== "/login" && User) {
		store.dispatch(logoutUser());
		store.dispatch(errorNotification("Your session expired.", "Please log in again to continue."));
		history.push("/login");
		return false;
	}
	store.dispatch(setConnectedStatus(true));
	return true;
};

const handleReject = (reject: (reason: any) => void, error: any) => {
	const failedToFetch = error && error.message === "Failed to fetch";
	store.dispatch(setConnectedStatus(!failedToFetch));
	reject(error);
}

const fetchHeaders = () => {
	const { User: { token }} = store.getState();

	return new Headers({
		"Content-Type": "application/json",
		"Authorization": `Bearer ${token}`,
		"X-Connect-Client": VERSION
	});
};

/**
 * abstract fetch calls under some sweet sugar
 * these verb abstractions assume a logged in
 * user and include the companyId in the baseUrl
 * and the email/token headers required for
 * authed requests
 */
const GET = (path: string, admin?: boolean, raw?: boolean): Promise<{} | null> => {
	return new Promise((resolve, reject) => {
		fetch(getBaseUrl(path, admin), {
			method: "GET",
			mode: "cors",
			headers: fetchHeaders()
		}).then((response) => {
			store.dispatch(setConnectedStatus(true));
			if (raw) {
				resolve(response);
			}

			if (sessionIsValid(response)) {
				if (response.status === 204) {
					resolve({});
				} else {
					return response.json();
				}
			}

			return null;
		})
			.then((response) => {
				if (response) {
					resolve(response);
				}
				resolve({});
			// note this leaves the promise unresolved which isn't the best :(
			}, (error) => {
				handleReject(reject, error);
			})
			.catch((error) => {
				store.dispatch(setConnectedStatus(true));
				notifyBugSnag(new Error(error));
				reject(error);
			});
	});
};

const POST = (path: string, params: {}, admin?: boolean, raw?: boolean): Promise<{} | null> => {
	return new Promise((resolve, reject) => {
		fetch(getBaseUrl(path, admin), {
			method: "POST",
			mode: "cors",
			headers: fetchHeaders(),
			body: raw ? String(params) : JSON.stringify(params)
		}).then((response) => {
			store.dispatch(setConnectedStatus(true));
			if (sessionIsValid(response)) {
				// if we get a 204 status, we know it succeeded
				// but it will have no body to json
				if (response.status === 204) {
					resolve({});
				} else {
					return response.json();
				}
			}

			return null;
		})
			.then((response) => {
			// deal with api / symfony errors that aren't formatted
				if (response && (response.message && response.exception || response.errors)) {
					reject(response);
				} else if (response) {
					resolve(response);
				}
			// note this leaves the promise unresolved which isn't the best :(
			}, (error) => {
				handleReject(reject, error);
			})
			.catch((error) => {
				store.dispatch(setConnectedStatus(true));
				notifyBugSnag(new Error(error));
				reject(error);
			});
	});
};

const PUT = (path: string, params: {}, admin?: boolean): Promise<{} | null> => {
	return new Promise((resolve, reject) => {
		fetch(getBaseUrl(path, admin), {
			method: "PUT",
			mode: "cors",
			headers: fetchHeaders(),
			body: JSON.stringify(params)
		}).then((response) => {
			store.dispatch(setConnectedStatus(true));
			if (sessionIsValid(response)) {
				// if we get a 204 status, we know it succeeded
				// but it will have no body to json
				if (response.status === 204) {
					resolve({});
				} else {
					return response.json();
				}
			}
			return null;
		})
			.then((response) => {
				if (response && response.errors || response.exception) {
					reject(response);
				} else if (response && !response.exception) {
					resolve(response);
				}
			// note this leaves the promise unresolved which isn't the best :(
			}, (error) => {
				if (error && error.exception && error.message) {
					reject(error.message);
				}

				handleReject(reject, error);
			})
			.catch((error) => {
				store.dispatch(setConnectedStatus(true));
				notifyBugSnag(new Error(error));
				reject(error);
			});
	});
};

const DELETE = (path: string, params?: {}, admin?: boolean): Promise<{} | null> => {
	return new Promise((resolve, reject) => {
		fetch(getBaseUrl(path, admin), {
			method: "DELETE",
			mode: "cors",
			headers: fetchHeaders(),
			body: JSON.stringify(params)
		})
			.then((response) => {
				store.dispatch(setConnectedStatus(true));
				if (sessionIsValid(response)) {
				// if we get a 204 status, we know it succeeded
				// but it will have no body to json
					if (response.status === 204) {
						resolve({});
					} else if (!response.ok) {
						response.json()
							.then((error) => {
								reject(error);
							});
					} else {
						return response.json();
					}
				}

				return null;
			})
			.then((response) => {
				if (response) {
					resolve(response);
				}
			// note this leaves the promise unresolved which isn't the best :(
			}, (error) => {
				handleReject(reject, error);
			})
			.catch((error) => {
				store.dispatch(setConnectedStatus(true));
				notifyBugSnag(new Error(error));
				reject(error);
			});
	});
};

const DOWNLOAD = (url: string) => {
	fetch(url, {
		method: "GET",
		mode: "cors",
		headers: fetchHeaders()
	})
		.then((response) => {
			return response.arrayBuffer()
				.then((res) => {
				// Extract file name from the content disposition header if one exists
					let disposition = response.headers.get("content-disposition");
					let matchName = /filename=\"?([^\"]+)\"?/;
					let { 1: fileName } = (matchName.exec(disposition || "") || []);
					// once stream is completed, send blob url to UI
					let blob = new Blob([ res ], { type: response.headers.get("content-type") || "" });
					Utils.initiateDownload(URL.createObjectURL(blob), fileName || "deviceDb");
				},
				(error) => {
				// Need to be throwing an error here, not sure what though
				})
				.catch((error) => {
					notifyBugSnag(new Error(error));
				});
		});
}

export { baseUrl, fetchHeaders, responseHasError, sessionIsValid, GET, POST, PUT, DELETE, DOWNLOAD };
