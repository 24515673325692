import { dom, library } from "@fortawesome/fontawesome-svg-core";
import * as brand from "@fortawesome/free-brands-svg-icons";
import * as light from "@fortawesome/pro-light-svg-icons";
import * as regular from "@fortawesome/pro-regular-svg-icons";
import * as solid from "@fortawesome/pro-solid-svg-icons";

// Add our solid, regular, and light icons to the library
export const icons = [ "faAd", "faAnalytics", "faArrowDown", "faArrowLeft", "faArrowRight", "faArrowUp", "faArrowsV",
	"faBan", "faBook", "faBug", "faBuilding", "faCalendar", "faCheckCircle", "faCheckSquare", "faChevronLeft",
	"faChevronRight", "faCircle", "faClipboardCheck", "faClock", "faCogs", "faCopy", "faEnvelope",
	"faEraser", "faExclamationCircle", "faExclamationTriangle", "faEye", "faFolder", "faGlobe",
	"faImage", "faLightbulb", "faListUl", "faLongArrowAltUp", "faLock", "faMapMarker", "faMedkit", "faMinusCircle",
	"faMobileAlt", "faMoon", "faPencil", "faPhone", "faPlay", "faPlus", "faPlusCircle", "faQuestionCircle",
	"faQuestionSquare", "faRocket", "faRunning", "faShield", "faSignIn", "faSignOutAlt", "faSmile", "faSync", "faThLarge",
	"faThList", "faTimes", "faTimesCircle", "faTrashAlt", "faUser", "faVolumeOff", "faFileAlt", "faWifi",
	"faMobile", "faChartBar", "faStar", "faArchive", "faPlayCircle", "faVideo", "faCaretRight",
	"faInfoCircle", "faHeartbeat", "faDownload", "faFlag", "faCog", "faWrench", "faFilm", "faPowerOff",
	"faRecycle", "faTachometer", "faDatabase", "faCloudDownload", "faUserClock", "faTrophyAlt",
	"faStoreAlt", "faUsers", "faTablet", "faShieldCheck", "faSortAlphaDown", "faUsersCog", "faPrint",
	"faHandPointRight", "faUserPlus", "faUnlock", "faRedo", "faSortAlphaUp", "faLongArrowAltDown", "faTextWidth",
	"faExchange", "faBell", "faBullseye", "faCheck", "faEyeSlash", "faPlusSquare", "faMinusSquare",
	"faTrash", "faTh", "faSave", "faFolderOpen", "faCaretDown", "faReplyAll", "faStore", "faChartLine",
	"faLifeRing", "faUser", "faVolumeUp", "faLightbulbOn", "faMagic", "faMoonStars", "faNetworkWired", "faMale",
	"faExpand", "faLockAlt", "faCopy", "faArrowToLeft", "faUserHeadset", "faListOl", "faRandom", "faTools" ];

// Add our brands icons to the library
export const brandIcons = [ "faAndroid" ];

export default function initFontAwesome() {
	icons.map((icon: string) => {
		library.add(solid[icon]);
		library.add(regular[icon]);
		library.add(light[icon]);
	});

	brandIcons.map((icon: string) => {
		library.add(brand[icon]);
	});

	dom.watch();
}