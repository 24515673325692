import { Progress, Switch } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, DeviceError, IDevice, MediaType, SpeedTestProgressArgs } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { DOWNLOAD } from "Api/V1/CRUD";
import DeviceSupportCard from "Components/Devices/DeviceHealthModal/DeviceSupportCard";
import { Colors } from "Components/Global/Constants";
import { IconWeights } from "Components/Global/Icon";
import { updateDeviceAsync, toggleMaintenanceModeAsync } from "Data/Actions/Devices";
import { setDeviceErrorLogModal } from "Data/Actions/UI/Modals";
import {
	setDeviceDBURL,
	setDeviceErrors,
	setDeviceScreenshotURL,
	setMediaPreview,
	setDeviceSpeedTestResult
} from "Data/Actions/UI";
import {
	clearDeviceCacheAsync,
	rebootDeviceAsync,
	requestDeviceDB,
	requestDeviceScreenshotAsync,
	updateAds,
	updateDeviceSoftwareAsync
} from "Data/Actions/UIAsync";
import { AppState } from "Data/Objects/AppState";
import Perishable from "Data/Objects/Perishable";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getDeviceHealthModalDevice, getDeviceHealthModalUUID } from "Data/Selectors/UI";
import { getCurrentApk } from "Data/Selectors/System";
import { getDeviceHealthModalDeviceState } from "Data/Selectors/UI";
import DevicesApi from "Api/Devices";
import { Api } from "Api/Api";
import { toggleFeature } from "@connect/Features";
import NetworkWarning  from "Components/Devices/NetworkWarning";
import { HelpPopover } from "Components/Global/Common";

const { lightGray, lightestGray, black, primaryBlue } = Colors;

const mapStateToProps = (state: AppState, ownProps: DeviceSupportPanelProps) => {
	const selectedUUID = getDeviceHealthModalUUID(state);
	const device = getDeviceHealthModalDevice(state);
	const currentApkVersion = getCurrentApk(state);
	const {
		adsUpdating, rebooting, fetchingScreenshot, fetchingDatabase, clearingCache, fetchingErrorLog,
		screenshotURL, databaseURL, deviceErrors, speedTest, deviceUpdating
	} = getDeviceHealthModalDeviceState(state, selectedUUID);

	return {
		adsUpdating, clearingCache, currentApkVersion, databaseURL, device, deviceErrors, deviceUpdating,
		fetchingDatabase, fetchingErrorLog, fetchingScreenshot, rebooting, screenshotURL, selectedUUID, speedTest
	};
}

const mapDispatchToProps = (dispatch, ownProps: DeviceSupportPanelProps) => {
	const { uuid } = ownProps.device;

	return {
		updateDevice: (device: IDevice) => dispatch(updateDeviceAsync(device)),
		updateAdsAsync: () => dispatch(updateAds(uuid)),
		requestDeviceDBAsync: () => dispatch(requestDeviceDB(uuid)),
		clearDeviceCache: () => dispatch(clearDeviceCacheAsync(uuid)),
		rebootDevice: () => dispatch(rebootDeviceAsync(uuid)),
		openErrorModal: () => dispatch(setDeviceErrorLogModal(true)),
		requestDeviceScreenshot: () => dispatch(requestDeviceScreenshotAsync(uuid)),
		resetErrorLogs: () => dispatch(setDeviceErrors(uuid, [], true)),
		resetDBURL: () => dispatch(setDeviceDBURL(uuid, "")),
		resetScreenshotURL: () => dispatch(setDeviceScreenshotURL(uuid, "")),
		setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => dispatch(setMediaPreview(uri, vimeoId, type)),
		showDeviceErrorLogModal: () => dispatch(setDeviceErrorLogModal(true)),
		updateDeviceSoftware: () => dispatch(updateDeviceSoftwareAsync(uuid)),
		setSpeedTestResults: (deviceUUID: string, result: SpeedTestProgressArgs) =>
			dispatch(setDeviceSpeedTestResult(deviceUUID, result)),
		toggleMaintenanceMode: () => dispatch(toggleMaintenanceModeAsync(uuid))
	}
};

interface DeviceSupportPanelProps {
	selectedUUID: string;
	device: IDevice;
	updateDevice: (device: IDevice) => void;
	adsUpdating: Perishable<boolean>;
	rebooting: Perishable<boolean>;
	fetchingScreenshot: Perishable<boolean>;
	fetchingDatabase: Perishable<boolean>;
	clearingCache: Perishable<boolean>;
	fetchingErrorLog: Perishable<boolean>;
	deviceUpdating: Perishable<boolean>;

	screenshotURL: string;
	databaseURL: string;
	deviceErrors: DeviceError[];
	speedTest: SpeedTestProgressArgs;
	currentApkVersion: string;

	updateAdsAsync: () => void;
	requestDeviceDBAsync: () => void;
	clearDeviceCache: () => void;
	rebootDevice: () => void;
	openErrorModal: () => void;
	requestDeviceScreenshot: () => void;
	resetErrorLogs: () => void;
	resetDBURL: () => void;
	resetScreenshotURL: () => void;
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => void;
	showDeviceErrorLogModal: () => void;
	updateDeviceSoftware: () => void;
	setSpeedTestResults: (deviceUUID: string, result: SpeedTestProgressArgs) => void;
	toggleMaintenanceMode: () => void;
}

export class DeviceSupportPanel extends React.Component<DeviceSupportPanelProps> {
	constructor(props: DeviceSupportPanelProps) {
		super(props);

		this.styles = {
			grid: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr",
				gridGap: 16,
				margin: 8
			},
			unavailable: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: 24,
				width: "100%",
				color: lightGray
			},
			split: {
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				width: 250,
				position: "absolute",
				bottom: 20,
				padding: "0px 66px"
			},
			speed: {
				padding: "0px 35px"
			},
			speedText: {
				textAlign: "center",
				color: black
			},
			text: {
				color: lightGray,
				fontSize: ".8em"
			},
			progressStyle: {
				position: "absolute",
				top: 30,
				width: 190
			},
			separator: {
				width: 1,
				height: 29,
				background: lightestGray
			}
		};

		this.downloadDeviceDatabase = this.downloadDeviceDatabase.bind(this);
		this.initiateRebootDevice = this.initiateRebootDevice.bind(this);
		this.initiateSpeedTest = this.initiateSpeedTest.bind(this);
		this.saveErrorLogs = this.saveErrorLogs.bind(this);
		this.viewErrorLogs = this.viewErrorLogs.bind(this);
	}

	baseStyle: CustomCSS;
	styles: CustomCSS;
	renderTimeout: any;

	// TODO: These timeouts need to be addressed when we have a better solution
	// Until then they are needed to force updates when the Perishable items update
	componentDidMount() {
		const { speedTest } = this.props;

		// Reset our speed test results if we've been waiting over 5 minutes
		const speedTestExpired = (new Date().getTime() - new Date(speedTest?.startedAt || "").getTime()) > 300000
		if (speedTestExpired) {
			this.resetSpeedTest();
		}

		this.renderTimeout = setInterval(() => {
			this.forceUpdate()
		}, 10000);
	}

	componentWillUnmount() {
		clearInterval(this.renderTimeout);
	}

	render() {
		return (
			<React.Fragment>
				<NetworkWarning />
				<div style={ this.styles.grid }>
					{ this.renderUpdateAds() }
					{ this.renderRemoteReboot() }
					{ this.renderClearCache() }
					{ this.renderViewErrorLog() }
					{ this.renderSpeedTest() }
					{ this.renderDeviceDatabase() }
					{ this.renderUpdateSoftware() }
					{ this.renderMaintenanceMode() }
				</div>
			</React.Fragment>
		);
	}

	renderUpdateAds() {
		const { adsUpdating, rebooting, updateAdsAsync } = this.props;
		const loading = adsUpdating && adsUpdating.value;
		const isRebooting = rebooting && rebooting.value;
		let icon = "film";

		if (loading) {
			icon = "sync";
		}

		return (
			<DeviceSupportCard
				icon={ icon }
				onClick={ updateAdsAsync }
				title="Update Ads"
				spin={ !!loading }
				disabled={ !!(loading || isRebooting) }
			/>
		);
	}

	renderRemoteReboot() {
		const { rebooting } = this.props;
		const loading = rebooting && rebooting.value;
		let icon = "power-off";

		if (loading) {
			icon = "sync";
		}

		return (
			<DeviceSupportCard
				icon={ icon }
				title="Remote Reboot"
				onClick={ this.initiateRebootDevice }
				disabled={ !!loading }
				spin={ !!loading }
			/>
		);
	}

	renderClearCache() {
		const { clearingCache, rebooting, clearDeviceCache } = this.props;
		const loading = clearingCache && clearingCache.value;
		const isRebooting = rebooting && rebooting.value;
		let icon = "recycle";
		let iconWeight: IconWeights = "regular";

		if (loading) {
			icon = "sync";
			iconWeight = "solid";
		}

		return (
			<DeviceSupportCard
				icon={ icon }
				iconWeight={ iconWeight }
				title="Clear Cache"
				onClick={ clearDeviceCache }
				disabled={ !!(loading || isRebooting) }
				spin={ !!loading }
			/>
		);
	}

	renderSpeedTest() {
		const { speedTest, rebooting } = this.props;
		const { down, up, downPercent, upPercent, device, failed } = speedTest;
		const { split, speed, progressStyle, speedText, separator, text } = this.styles;
		const isRebooting = rebooting && rebooting.value;
		const loading = device && (downPercent < 100 || upPercent < 100);
		const finished = device && downPercent === 100 && upPercent === 100;
		let icon = "tachometer";
		let content;

		if ((loading || finished) && !failed) {
			const progress = (downPercent + upPercent) / 2;
			icon = "sync";
			content = (
				<div style={{
					...split,
					...speed
				}}>
					<Progress
						percent={ progress }
						style={{
							...progressStyle,
							display: finished ? "none" : "block"
						}}
						showInfo={ false }
						strokeColor={ primaryBlue }
						strokeWidth={ 3 }
					/>
					<div style={ speedText }>
						{ down.toFixed(2) }<br />
						<span style={ text }>Mbps download</span>
					</div>
					<div style={ separator } />
					<div style={ speedText }>
						{ up.toFixed(2) }<br />
						<span style={ text }>Mbps upload</span>
					</div>
				</div>
			);
		}

		if (finished || failed) {
			icon = "tachometer"
		}

		return (
			<DeviceSupportCard
				icon={ icon }
				title="Device Speed Test"
				onClick={ this.initiateSpeedTest }
				disabled={ !!(loading || isRebooting) && !failed }
				spin={ !failed && !!loading }
			>
				{ content }
			</DeviceSupportCard>
		);
	}

	renderDeviceDatabase() {
		if (!hasPermission(PERMISSIONS.DEVICES_MANAGE)) {
			return null;
		}

		const { databaseURL, fetchingDatabase, requestDeviceDBAsync } = this.props;
		const loading = fetchingDatabase && fetchingDatabase.value;
		const rebooting = this.props.rebooting && this.props.rebooting.value;

		let content;
		let icon = "database";

		if (loading) {
			icon = "sync";
		}

		if (databaseURL) {
			content = (
				<a onClick={ this.downloadDeviceDatabase }>Download Database</a>
			);
		}

		return (
			<DeviceSupportCard
				icon={ icon }
				title="Device Database"
				spin={ !!loading }
				onClick={ requestDeviceDBAsync }
				disabled={ !!(loading || rebooting) }
			>
				{ content }
			</DeviceSupportCard>
		);
	}

	renderViewErrorLog() {
		const { fetchingErrorLog, openErrorModal } = this.props;
		const loading = fetchingErrorLog && fetchingErrorLog.value;
		const rebooting = this.props.rebooting && this.props.rebooting.value;


		let icon = "file-alt";

		if (loading) {
			icon = "sync"
		}


		return (
			<DeviceSupportCard
				icon={ icon }
				title="Request Error Log"
				onClick={ openErrorModal }
				spin={ !!loading }
				disabled={ !!(loading || rebooting) }
			>
			</DeviceSupportCard>
		);
	}

	renderUpdateSoftware() {
		const { updateDeviceSoftware, deviceUpdating, currentApkVersion, device } = this.props;
		const loading = deviceUpdating && deviceUpdating.value;
		const rebooting = this.props.rebooting && this.props.rebooting.value;
		const hidden = currentApkVersion === device.softwareVersion;
		const icon = loading ? "sync" : "cloud-download";

		return (
			<DeviceSupportCard
				icon={ icon }
				title="Update Device Software"
				onClick={ updateDeviceSoftware }
				spin={ !!loading }
				disabled={ !!(loading || rebooting) }
				hidden={ hidden }
			/>
		);
	}

	renderMaintenanceMode() {
		const { deviceUpdating, toggleMaintenanceMode, device: { maintenanceMode } } = this.props;
		const loading = deviceUpdating && deviceUpdating.value;
		const rebooting = this.props.rebooting && this.props.rebooting.value;
		const title = (
			<React.Fragment>
				Maintenance Mode
				<HelpPopover title="Maintenance Mode">
					While in Maintenance Mode the Connect PVM will temporarily allow the camera to reboot when
					performing camera firmware updates. Ads with the camera component may have an interruption
					of the security feed during this time.
				</HelpPopover>
			</React.Fragment>
		)

		return (
			<DeviceSupportCard
				icon="tools"
				title={ title }
				disabled={ !!(loading || rebooting) }
			>
				<Switch onClick={ toggleMaintenanceMode } checked={ maintenanceMode }/>
			</DeviceSupportCard>
		)
	}

	viewErrorLogs() {
		const { deviceErrors } = this.props;

		if (deviceErrors && deviceErrors.length > 0) {
			this.props.showDeviceErrorLogModal();
		}
	}

	saveErrorLogs() {
		const { device, deviceErrors } = this.props;
		const csvContent = "data:text/csv;charset=utf-8," + Utils.getCsvFromObjectArray(deviceErrors);
		const encodedUri = encodeURI(csvContent);
		Utils.initiateDownload(encodedUri, `${ device.name }-log`);
	}

	downloadDeviceDatabase() {
		const { databaseURL, resetDBURL } = this.props;
		if (databaseURL && databaseURL.length > 0) {
			DOWNLOAD(databaseURL);
			resetDBURL();
		}
	}

	initiateRebootDevice() {
		Notifications.confirm(
			"Reboot Device",
			"Are you sure you want to reboot this device?",
			"Reboot",
			"Cancel",
			this.props.rebootDevice
		);
	}

	resetSpeedTest() {
		const { setSpeedTestResults, device } = this.props;

		const resetResults: SpeedTestProgressArgs = {
			device: device.uuid,
			down: 0,
			downPercent: 0,
			up: 0,
			upPercent: 0,
			startedAt: new Date().toISOString()
		};

		setSpeedTestResults(resetResults.device, resetResults);
	}

	initiateSpeedTest() {
		const devicesApi = new DevicesApi();
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.startSpeedTest.bind(devicesApi),
			Api.DeviceApi.requestSpeedTest.bind(Api.DeviceApi)
		);

		this.resetSpeedTest();
		apiMethod(this.props.device.uuid);
	}

	initiateRequestDeviceScreenshot() {
		const { requestDeviceScreenshot, screenshotURL, setMediaPreview: setPreview } = this.props;
		if (screenshotURL) {
			setPreview(screenshotURL, -1, "image");
		} else {
			requestDeviceScreenshot();
		}
	}

	hideScreenshotModal() {
		const { resetScreenshotURL } = this.props;
		resetScreenshotURL();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSupportPanel);