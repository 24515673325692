import * as moment from "moment";

import { ActionSet, ActionType, Activity, AnalyticsResult, ApkVersion, Context, CustomPermissions,
	Deployment, DeploySteps, DeviceError, DeviceModel, FeatureToggle, FinishedAnalyticsReport, HealthReport,
	HealthReportResult, IAd, IAdTemplate, ICompany, IDevice, IDeviceGroup, IExtendedCompany, ILocationState, IMedia,
	IMediaUpload, IMenuItem, IPlaylist, IStore, IUser, MediaContext, MediaType, RolesList, SpeedTestProgressArgs,
	StringArrayObject, StringOrStringArrayObject, Team, NameUuid, Credential
} from "@connect/Interfaces";
import { Company } from "Data/Objects/Company";
import Perishable from "Data/Objects/Perishable";
import {
	initialActiveFilterStates,
	initialActiveSearchStates,
	initialActiveSelectionStates,
	initialActiveSortStates,
	initialActiveTagStates,
	initialActiveUuidStates,
	initialActiveViewStates,
	initialAsyncStates
} from "Data/Objects/UI";

// NOTE: this value is set by the build scripts and should never be changed manually
export const VERSION = "1.71.0";

export class AccordionState {
	[key: string]: string[];
}

export class ActiveFilterState {
	constructor(pages?: StringArrayObject | StringArrayObject[]) {
		Object.keys(pages || {}).forEach((key) => {
			this[key] = (pages as {})[key].reduce((filterSet, filter) => {
				if (typeof(filter) === "object") {
					// if this filter is an empty array, set it as such
					Object.keys(filter).forEach((f) => {
						filterSet[f] = [];
					});
				} else {
					// default filter state for any filter enum should be the first in the list
					// deployments and ads do not use an enum for filter, so we need to use the correct default
					filterSet[filter] = (() => {
						switch (key) {
							case "deployments":
								return "all";
							case "ads":
							case "analytics":
							case "deployDevices":
							case "healthDevices":
							case "healthReports":
								return "";
							default:
								return "0";
						}
					})();
				}

				return filterSet;
			}, {});
		});
	}

	[key: string]: StringOrStringArrayObject;
}

export class ActiveStringState {
	constructor(keys: string[], defaultValue?: string) {
		for (let key of keys || []) {
			this[key] = defaultValue || "";
		}
	}

	[key: string]: string;
}

export class ActiveObjectArrayState {
	constructor(stateObjectArray: {}[]) {
		stateObjectArray.forEach((object) => {
			const [ key ] = Object.keys(object);
			const [ value ] = Object.values(object);
			this[key] = value;
		});
	}
}

export class ActiveStringArrayState {
	constructor(keys?: string[]) {
		for (let key of keys || []) {
			this[key] = [];
		}
	}

	[key: string]: string[];
}

export class ActionBuilderState {
	constructor() {
		this.selectedTrigger = -1;
		this.selectedAction = "";
	}

	selectedTrigger: number;
	selectedAction: ActionType;
}

export class AdBuilderState {
	constructor() {
		this.componentIsResizing = [];
		this.livePreview = true;
		this.selectedComponentIndex = -1;
		this.slideshowCurrentSlide = -1;
	}

	activeAd: IAd;
	componentIsResizing: boolean[];
	livePreview: boolean;
	selectedComponentIndex: number;
	slideshowCurrentSlide: number;
}

export class AsyncState {
	constructor() {
		this.currentlyFetching = false;
		this.currentPage = 0;
		this.haveAllData = false;
		this.lastFetchedCompany = "";
		this.query = {};
	}

	currentlyFetching: boolean;
	currentPage: number;
	haveAllData: boolean;
	lastFetch: moment.Moment;
	lastFetchedCompany: string;
	query?: {
		[filter: string]: AsyncState;
	};
}

export class AsyncStates {
	constructor(asyncStates?: string[]) {
		for (let key of asyncStates || []) {
			this[key] = new AsyncState();
		}
	}

	[key: string]: AsyncState;
}

export class DeviceHealthModalState {
	constructor() {
		this.adsUpdating = new Perishable<boolean>(false);
		this.clearingCache = new Perishable<boolean>(false);
		this.databaseURL = "";
		this.deviceErrors = [];
		this.fetchingDatabase = new Perishable<boolean>(false);
		this.fetchingErrorLog = new Perishable<boolean>(false);
		this.fetchingScreenshot = new Perishable<boolean>(false);
		this.pingStatus = new Perishable<boolean>(false);
		this.rebooting = new Perishable<boolean>(false);
		this.screenshotTime = "";
		this.screenshotURL = "";
		this.speedTest = new SpeedTestState();
		this.deviceUpdating = new Perishable(false);
	}

	adsUpdating: Perishable<boolean>;
	clearingCache: Perishable<boolean>;
	databaseURL: string;
	deviceErrors: DeviceError[];
	fetchingDatabase: Perishable<boolean>;
	fetchingErrorLog: Perishable<boolean>;
	pingStatus: Perishable<boolean>;
	fetchingScreenshot: Perishable<boolean>;
	rebooting: Perishable<boolean>;
	screenshotTime: string;
	screenshotURL: string;
	fetchingSnapshot: Perishable<boolean>;
	lastSeen: moment.Moment;
	speedTest: SpeedTestProgressArgs;
	deviceUpdating: Perishable<boolean>;
}

export class DeviceHealthModalStates {
	constructor(modalStates?: string[]) {
		for (let key of modalStates || []) {
			this[key] = new DeviceHealthModalState();
		}
	}

	[key: string]: DeviceHealthModalState;
}

export class AppState {
	constructor() {
		this.Actions = new ActionsState();
		this.Admin = new AdminState();
		this.Ads = new AdsState();
		this.Company = new CompanyState();
		this.Deployments = new DeploymentsState();
		this.Devices = new DevicesState();
		this.HealthReports = new HealthReportState();
		this.Media = new MediaState();
		this.Permissions = new PermissionsState();
		this.Playlists = new PlaylistState();
		this.Pusher = new PusherState();
		this.Roles = new RolesState();
		this.System = new SystemState();
		this.UI = new UIState();
		this.User = new UserState();
	}

	Actions: ActionsState;
	Admin: AdminState;
	Ads: AdsState;
	Company: CompanyState;
	Deployments: DeploymentsState;
	Devices: DevicesState;
	HealthReports: HealthReportState;
	Media: MediaState;
	Permissions: PermissionsState;
	Playlists: PlaylistState;
	Pusher: PusherState;
	Roles: RolesState;
	System: SystemState;
	UI: UIState;
	User: UserState;
	Router: any;
}

export class AdminActivityState {
	constructor() {
		this.activities = [];
	}

	activities: Activity[];
}

export class AdminVersionsState {
	constructor() {
		this.versions = [];
	}

	versions: ApkVersion[];
	currentVersion: ApkVersion;
}

export class AdminUsersState {
	constructor() {
		this.allUsers = [];
		this.users = [];
	}

	allUsers: IUser[];
	users: IUser[];
}

export class AdminState {
	constructor() {
		this.Activity = new AdminActivityState();
		this.Users = new AdminUsersState();
		this.Versions = new AdminVersionsState();
	}

	Activity: AdminActivityState;
	Users: AdminUsersState;
	Versions: AdminVersionsState;
}

export class CompanyState {
	constructor() {
		this.activeCompanyId = "";
		this.activeCompany = new Company();
		this.adminTeams = [];
		this.companies = [];
		this.managedCompanies = [];
		this.stores = [];
		this.teams = [];
	}

	activeCompanyId: string;
	activeCompany: ICompany;
	adminTeams: Team[];
	companies: ICompany[];
	managedCompanies: IExtendedCompany[];
	stores: IStore[];
	teams: Team[];

	static reconstruct(state: CompanyState) {
		return {
			activeCompanyId: state.activeCompanyId || "",
			activeCompany: state.activeCompany || new Company(),
			adminTeams: state.adminTeams || [],
			companies: state.companies || [],
			managedCompanies: state.managedCompanies || [],
			stores: state.stores || [],
			teams: state.teams || []
		};
	}
}

export class DeploymentsState {
	constructor() {
		this.deployments = [];
		this.approvers = [];
	}

	deployments: Deployment[];
	approvers: IUser[];
}

export class DevicesState {
	constructor() {
		this.devices = [];
		this.unassociatedDevices = [];
		this.deviceGroups = [];
		this.deviceModels = [];
		this.deviceModelNames = [];
	}

	deviceModels: DeviceModel[];
	deviceModelNames: string[];
	devices: IDevice[];
	unassociatedDevices: IDevice[];
	deviceGroups: IDeviceGroup[];
}

export class HealthReportState {
	constructor() {
		this.reports = [];
		this.results = [];
		this.downloadingReports = [];
	}

	reports: HealthReport[];
	results: HealthReportResult[];
	downloadingReports: string[];
}

export class MediaPreviewState {
	constructor() {
		this.uri = "";
		this.vimeoId = -1;
	}

	type: MediaType;
	uri: string;
	vimeoId: number;
}

export class MediaState {
	constructor() {
		this.media = [];
		this.uploads = [];
	}

	media: IMedia[];
	uploads: IMediaUpload[];
}

export class RolesState {
	constructor() {
		this.editRoles = {};
		this.roles = {};
	}

	editRoles: RolesList;
	roles: RolesList;
}

export const initialUnsavedStates = [ "ads" ];

export class SystemState {
	constructor() {
		this.connected = true;
		this.features = [];
		this.location = {
			current: new LocationState(),
			previous: new LocationState()
		};
		this.redirect = new LocationState();
		this.unsaved = new ActiveStringState(initialUnsavedStates, "All changes saved");
		this.videos = {};
		this.currentApk = {};
	}

	connected: boolean;
	features: FeatureToggle[];
	location: {
		current: LocationState;
		previous: LocationState;
	};

	redirect: LocationState;
	unsaved: ActiveStringState;
	videos: {};
	currentApk: Partial<ApkVersion>;
	pusherCredentials: Credential
}

export class PermissionsState {
	permissions: CustomPermissions;
}

export class PlaylistState {
	constructor() {
		this.playlists = [];
	}

	playlists: IPlaylist[];
}

export class DeviceErrorLogModalState {
	constructor() {
		this.visible = false;
	}

	visible: boolean;
}

export class CheckinDeviceModalState {
	constructor() {
		this.visible = false;
	}

	visible: boolean;
}

export class CreateStoreModalState {
	constructor() {
		this.visible = false;
	}

	visible: boolean;
}

export class CreateUserModalState {
	constructor() {
		this.visible = false;
	}

	visible: boolean;
}

export class DeviceGroupTreeBrowserModalState {
	constructor() {
		this.selection = "";
		this.visible = false;
	}

	selection: string;
	visible: boolean;
}

export class RequestNameModalState {
	constructor() {
		this.type = "";
		this.visible = false;
	}

	adTemplate?: IAdTemplate;
	type: string;
	visible: boolean;
}

export class SelectAdsModalState {
	constructor() {
		this.context = Context.PAGE
		this.visible = false;
	}

	adTemplate?: IAdTemplate;
	context: Context;
	visible: boolean;
}

export class SelectMediaModalState {
	constructor() {
		this.visible = false;
		this.selection = [];
		this.context = MediaContext.PAGE;
		this.orientation = "";
	}

	visible: boolean;
	selection: string[];
	context: MediaContext;
	orientation: string;
}

export class SelectActionsModalState {
	constructor() {
		this.visible = false;
		this.context = Context.PAGE;
	}

	context: Context;
	visible: boolean;
}

export class SelectPlaylistsModalState {
	constructor() {
		this.visible = false;
		this.selection = [];
		this.context = Context.PAGE;
	}

	visible: boolean;
	selection: string[];
	context: Context;
}

export class TemplatesListModalState {
	constructor() {
		this.visible = false;
	}

	visible: boolean;
}

export class ModalsState {
	constructor() {
		this.activeDeviceHealthModalUUID = "";
		this.companyCreateModalVisible = false;
		this.companyRequestModalVisible = false;
		this.deploymentApprovalModalVisible = false;
		this.mediaPreviewModalVisible = false;
		this.privacyPolicyModalVisible = false;
		this.checkinDeviceModal = new CheckinDeviceModalState();
		this.createStoreModal = new CreateStoreModalState();
		this.createUserModal = new CreateUserModalState();
		this.deviceErrorLogModal = new DeviceErrorLogModalState();
		this.deviceGroupTreeBrowser = new DeviceGroupTreeBrowserModalState();
		this.requestNameModal = new RequestNameModalState();
		this.selectActionsModal = new SelectActionsModalState();
		this.selectAdsModal = new SelectAdsModalState();
		this.selectMediaModal = new SelectMediaModalState();
		this.selectPlaylistsModal = new SelectPlaylistsModalState();
		this.templatesListModal = new TemplatesListModalState();
		this.termsConditionsModalVisible = false;
	}

	activeDeviceHealthModalUUID: string;
	companyCreateModalVisible: boolean;
	companyRequestModalVisible: boolean;
	deploymentApprovalModalVisible: boolean;
	mediaPreviewModalVisible: boolean;
	privacyPolicyModalVisible: boolean;
	deviceErrorLogModal: DeviceErrorLogModalState;
	deviceGroupTreeBrowser: DeviceGroupTreeBrowserModalState;
	checkinDeviceModal: CheckinDeviceModalState;
	createStoreModal: CreateStoreModalState;
	createUserModal: CreateUserModalState;
	requestNameModal: RequestNameModalState;
	selectActionsModal: SelectActionsModalState;
	selectAdsModal: SelectAdsModalState;
	selectMediaModal: SelectMediaModalState;
	selectPlaylistsModal: SelectPlaylistsModalState;
	templatesListModal: TemplatesListModalState;
	termsConditionsModalVisible: boolean;
}

export class NavigationItemState {
	constructor() {
		this.currentLocation = "/";
		this.previousLocation = "/";
	}

	currentLocation: string;
	previousLocation: string;
}

export class UIState {
	constructor() {
		this.activeFilters = new ActiveFilterState(initialActiveFilterStates);
		this.activeHealthReport = "";
		this.activeNavigationItem = new NavigationItemState();
		this.activeSearch = new ActiveStringState(initialActiveSearchStates);
		this.activeSelection = new ActiveStringArrayState(initialActiveSelectionStates);
		this.activeSorts = new ActiveObjectArrayState(initialActiveSortStates);
		this.activeTags = new ActiveStringArrayState(initialActiveTagStates);
		this.activeUuids = new ActiveStringState(initialActiveUuidStates);
		this.activeViews = new ActiveStringState(initialActiveViewStates);
		this.actionBuilderState = new ActionBuilderState();
		this.adBuilderState = new AdBuilderState();
		this.asyncState = new AsyncStates(initialAsyncStates);
		this.collapse = new AccordionState();
		this.deviceHealthModalState = new DeviceHealthModalStates();
		this.modals = new ModalsState();
		this.finishedReports = [];
		this.leftAccordion = new AccordionState();
		this.mediaPreview = new MediaPreviewState();
		this.menu = [];
		this.runningReports = [];
		this.expandedDeviceGroups = [];
		this.deploymentWizardState = new DeploymentWizardState();
	}

	activeFilters: ActiveFilterState;
	activeHealthReport: string;
	activeNavigationItem: NavigationItemState;
	activeSearch: ActiveStringState;
	activeSelection: ActiveStringArrayState;
	activeSorts: ActiveObjectArrayState;
	activeTags: ActiveStringArrayState;
	activeUuids: ActiveStringState;
	activeViews: ActiveStringState;
	actionBuilderState: ActionBuilderState;
	adBuilderState: AdBuilderState;
	asyncState: AsyncStates;
	collapse: AccordionState;
	deviceHealthModalState: DeviceHealthModalStates;
	modals: ModalsState;
	finishedReports: string[];
	leftAccordion: AccordionState;
	mediaPreview: MediaPreviewState;
	menu: IMenuItem[];
	runningReports: string[];
	expandedDeviceGroups: string[];
	deploymentWizardState: DeploymentWizardState;

	static getNewActiveSelectionState() {
		return new ActiveStringArrayState(initialActiveSelectionStates);
	}
}

export class LocationState implements ILocationState {
	constructor() {
		this.pathname = "";
		this.search = "";
		this.state = "";
		this.hash = "";
		this.key = "";
	}

	pathname: string;
	search: string;
	state: string;
	hash: string;
	key?: string;
}

export class UserState {
	constructor() {
		this.token = "";
	}

	token: string;
	user: IUser;
}

export class AdsState {
	constructor() {
		this.ads = [];
		this.analytics = [];
		this.finishedReports = [];
		this.pendingReports = [];
	}

	ads: IAd[];
	analytics: AnalyticsResult[];
	finishedReports: FinishedAnalyticsReport[];
	pendingReports: string[];
}

export class SpeedTestState {
	constructor() {
		this.up = 0;
		this.down = 0;
		this.device = "";
		this.upPercent = 0;
		this.downPercent = 0;
	}

	up: number;
	down: number;
	device: string;
	upPercent: number;
	downPercent: number;
	startedAt: string;
}

export class DeploymentWizardState {
	constructor() {
		this.scheduleDates = [ "", "" ];
		this.scheduled = false;
	}

	activeDeployment: Deployment;
	scheduleDates: [string, string];
	scheduled: boolean;
	step: DeploySteps;
}

export class ActionsState {
	constructor() {
		this.actionSets = [];
	}

	actionSets: ActionSet[];
}

export class PusherState {
	constructor() {
		this.lastNotification = {};
		this.presenceChannels = {};
	}

	lastNotification: {
		[channel: string]: string
	};

	presenceChannels: {
		[channel: string]: NameUuid[];
	}
}