import {
	BulkCheckinResult, ErrorResultV2, HeaderObject, IDevice, IDeviceGroup,
	IDevicesApi, PaginatedDataResult, PartialDeviceGroup, UUIDResult, DeviceError
} from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class DevicesApi extends ApiV2 implements IDevicesApi {
	attachDevices(devices: string[], parent: string): Promise<ErrorResultV2> {
		return this.POST(`devices/groups/${parent}/attach`, { devices }) as Promise<ErrorResultV2>;
	}

	createDeviceGroup(name: string, parent?: IDeviceGroup): Promise<PartialDeviceGroup> {
		const params = parent ? { name, parent: parent.uuid } : { name };

		return this.POST("devices/groups", params) as Promise<PartialDeviceGroup>;
	}

	deleteDeviceGroup(group: IDeviceGroup, force?: boolean): Promise<ErrorResultV2> {
		const query = force ? "?force" : "";

		return this.DELETE(`/devices/groups/${ group.uuid }${ query }`) as Promise<ErrorResultV2>;
	}

	detachDevices(devices: string[], parent: string): Promise<ErrorResultV2> {
		return this.POST(`devices/groups/${parent}/detach`, { devices }) as Promise<ErrorResultV2>;
	}

	getDevice(uuid: string): Promise<IDevice> {
		let params = {
			expand: "deviceGroup"
		};

		return this.GET(`devices/${ uuid }`, params, false) as Promise<IDevice>;
	}

	getDeviceGroups(perPage: number, page: number): Promise<PaginatedDataResult<IDeviceGroup>> {
		const params = {
			page,
			perPage
		}

		return this.GET("devices/groups", params) as Promise<PaginatedDataResult<IDeviceGroup>>;
	}

	getDevices(company: string, perPage: number, page: number, admin?: boolean): Promise<PaginatedDataResult<IDevice>> {
		const unassociated = company === "Unassociated";
		let params = {
			expand: "deviceGroup",
			filter: unassociated ? "unused" : "company",
			company: unassociated ? "" : company,
			perPage,
			page
		};

		return this.GET("devices", params, admin) as Promise<PaginatedDataResult<IDevice>>;
	}

	updateDevice(device: IDevice): Promise<ErrorResultV2> {
		return this.PUT(`devices/${ device.uuid }`, device) as Promise<ErrorResultV2>;
	}

	getDeviceErrorLogs(uuid: string, perPage: number, page: number): Promise<PaginatedDataResult<DeviceError>> {
		let params = {
			perPage,
			page
		};

		return this.GET(`devices/${ uuid }/errors`, params) as Promise<PaginatedDataResult<DeviceError>>;
	}

	updateDeviceGroup(group: IDeviceGroup, name: string): Promise<ErrorResultV2> {
		const { uuid, parent } = group;
		const params = {
			name,
			parent
		};

		return this.PUT(`devices/groups/${ uuid }`, params) as Promise<ErrorResultV2>;
	}

	checkinDevice(device: IDevice): Promise<UUIDResult> {
		return this.POST("devices", device, true) as Promise<UUIDResult>;
	}

	checkinDevices(devices: string): Promise<BulkCheckinResult[]> {
		return this.POST("devices/import", devices, true, {
			"Content-Type": "multipart/form-data"
		} as HeaderObject, true) as Promise<BulkCheckinResult[]>;
	}

	releaseDevice(uuid: string) {
		return this.DELETE(`devices/${ uuid }/release`, undefined, false) as Promise<null>;
	}

	removeDevice(uuid: string) {
		return this.DELETE(`devices/${ uuid }`, undefined, true) as Promise<null>;
	}

	updateAds(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "updateSchedule");
	}

	startSpeedTest(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "speedTest");
	}

	requestDatabase(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "uploadDatabase");
	}

	updateScreenshot(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "updateScreenshot");
	}

	updateSnapshot(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "updateDeviceView");
	}

	clearCache(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "clearCache");
	}

	pingDevice(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "ping");
	}

	updateSoftware(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "updateSoftware");
	}

	rebootDevice(deviceUuid: string) {
		return this.sendNotification("device-" + deviceUuid, "reboot");
	}

	setMaintenanceMode(deviceUuid: string, enabled: boolean) {
		return this.sendNotification("device-" + deviceUuid, "setMaintenanceMode", { enabled });
	}

	sendNotification(channels: string | string[], event: string, data?: any) {
		return this.POST(
			"messaging",
			{
				channels,
				event,
				data
			},
			true);
	}
}