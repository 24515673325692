import * as update from "immutability-helper";
import * as Pusher from "pusher-js";
import { createElement } from "react";
import { push } from "react-router-redux";

import { getState, store } from "@connect/Data";
import {
	AdAnalyticsReadyArgs, AdThumbnailChangedArgs, DeviceUUIDArgs, EventBinding,
	MediaChangedEventArgs, PermissionsChangedArgs, ScreenshotChangedArgs, SnapshotUpdateArgs,
	SpeedTestProgressArgs,	UpdateStoreArgs
} from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { setAnalyticsReportPending, updateAd } from "Data/Actions/Ads";
import { getAnalyticsReportDownloadAsync } from "Data/Actions/AdsAsync";
import { getCompanyInfoAsync, updateStore } from "Data/Actions/Company";
import { deleteDevice, fetchUpdatedDeviceAsync, updateDevice } from "Data/Actions/Devices";
import { getReportResultsAsync } from "Data/Actions/HealthReportAsync";
import { getMediaAsync } from "Data/Actions/MediaAsync";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { fetchPermissionSettings } from "Data/Actions/PermissionsAsync";
import { addReportNotification, removeReportNotification, removeRunningHealthReport, setDeviceClearCacheStatus,
	setDeviceDatabaseStatus, setDeviceDBURL, setDeviceLastSeen, setDeviceRebooting,
	setDeviceScreenshotStatus, setDeviceScreenshotTime, setDeviceScreenshotURL,
	setDeviceSnapshotStatus, setDeviceSoftwareUpdating, setDeviceSpeedTestResult, setDeviceUpdatingAds
} from "Data/Actions/UI";
import { setActiveAd } from "Data/Actions/UI/AdBuilder";
import { Ad } from "Data/Objects/Ads";
import { getActiveAd as getAdBuilderAd } from "Data/Selectors/AdBuilder";
import { getActiveAd as getAdById } from "Data/Selectors/Ads";
import { getActiveCompany, getActiveCompanyId, getStoreById } from "Data/Selectors/Company";
import { getAllDevices, getDeviceById } from "Data/Selectors/Devices";
import { getDeviceByUUID } from "Data/Selectors/UI";
import { getActiveUuid } from "Data/Selectors/UI";
import { makeGetMediaIsUploading } from "Data/Selectors/Media";
import { getActiveUserUuid } from "Data/Selectors/User";
import { notification } from "antd";
import { getPusherCredentials } from "Data/Selectors/System";

/*
 * Pusher Event Definitions
 */
const EVENTS = {
	media: "mediaStateChanged",
	adAnalyticsExportReady: "adAnalyticsExportReady",
	adThumbnailUpdated: "adThumbnailUpdated",
	generatingScreenshot: "generatingScreenshot",
	screenshotReceived: "screenshotReceived",
	snapshotRefreshed: "snapshotRefreshed",
	dbUploadStarted: "dbUploadStarted",
	dbUploadCompleted: "dbUploadCompleted",
	reportResultCompleted: "reportResultCompleted",
	scheduleUpdating: "scheduleUpdating",
	scheduleUpdated: "scheduleUpdated",
	rebootInitiated: "rebootInitiated",
	rebootCompleted: "rebootCompleted",
	cacheCleared: "cacheCleared",
	devicePong: "pongDevice",
	speedTestStarted: "speedTestStarted",
	speedTestProgress: "speedTestProgress",
	speedTestCompleted: "speedTestCompleted",
	speedTestFailed: "speedTestFailed",
	permissionsChanged: "permissionsChanged",
	deviceAssociated: "deviceAssociated",
	deviceReleased: "deviceReleased",
	softwareUpdating: "softwareUpdating",
	softwareUpdated: "softwareUpdated",
	updateStore: "updateStore",
	maintenanceModeChanged: "maintenanceModeChanged"
};

/*
 * Pusher Callbacks
 */
export const speedTestProgressCallback = (resultString: string) => {
	const result: SpeedTestProgressArgs = JSON.parse(resultString);
	store.dispatch(setDeviceSpeedTestResult(result.device, result));
}

export const speedTestFailedCallback = (resultString: string) => {
	const result: SpeedTestProgressArgs = JSON.parse(resultString);
	store.dispatch(errorNotification("Speed Test Failed", "Device speed test failed. Speed test cancelled."));
	store.dispatch(setDeviceSpeedTestResult(result.device, { ...result, failed: true }));
}

export const mediaChangedCallback = (result: MediaChangedEventArgs) => {
	const state = getState();
	const { state: mediaState, uuid } = result.media;

	// get our new cached selectors
	const getMediaIsUploading = makeGetMediaIsUploading();

	// check if we are uploading or have the media already
	const mediaIsUploading = getMediaIsUploading(state, uuid);

	// if we are not uploading and do not have the media, update our store
	if (result.success && mediaState === "ready" && !mediaIsUploading) {
		store.dispatch(getMediaAsync(result.media.uuid));
	}
}

export const adThumbnailUpdatedCallback = (result: AdThumbnailChangedArgs) => {
	const { uuid, thumbnail } = result;
	const state = getState();
	const ad: Ad = Object.assign({},
		getAdById(state, uuid),
		{
			thumbnail
		});
	const adBuilderAd = getAdBuilderAd(state);

	store.dispatch(updateAd(ad));

	// update our AdBuilder Ad if it is currently active
	if (adBuilderAd && adBuilderAd.uuid === uuid) {
		store.dispatch(setActiveAd(ad));
	}
}

export const screenshotReceivedCallback = (result: ScreenshotChangedArgs) => {
	const { device, url } = result;
	store.dispatch(successNotification("Device Screenshot Uploaded", "Device has finished uploading new screenshot"));
	store.dispatch(setDeviceScreenshotStatus(device, false));
	store.dispatch(setDeviceScreenshotURL(device, url));
	store.dispatch(setDeviceScreenshotTime(device, Utils.getISOTimestamp()));
}

export const snapshotRefreshedCallback = (result: SnapshotUpdateArgs) => {
	store.dispatch(setDeviceSnapshotStatus(result.device, false));
	const devices = getAllDevices(getState());
	const device = getDeviceByUUID(devices, result.device);
	store.dispatch(updateDevice({
		...device,
		snapshot: result.snapshot,
		snapshotThumbnail: result.snapshotThumbnail,
		snapshotTimestamp: result.snapshotTimestamp.replace(" ", "T").concat("+00:00")
	}));
}

export const dbUploadStartedCallback = (result: null) => {
	store.dispatch(successNotification("Database Uploading", "Device is uploading database"));
}

export const dbUploadCompletedCallback = (result: any) => {
	store.dispatch(successNotification("Database Uploaded", "Device database has successfully uploaded"));
	store.dispatch(setDeviceDatabaseStatus(result.device, false));
	store.dispatch(setDeviceDBURL(result.device, result.url));
}

export const handleHealthReportClick = (reportId: string) => {
	return () => {
		if (getActiveUuid(getState(), "health") === reportId) {
			store.dispatch(removeReportNotification(reportId));
		}

		store.dispatch(push(`/health/${reportId}`));
		notification.close(`health_report_${reportId}`);
	}
};

export const reportResultCompletedCallback = (result: any) => {
	store.dispatch(getReportResultsAsync(result.report));

	if (getState().UI.runningReports.indexOf(result.report) > -1) {
		Notifications.plain("Your report is ready", createElement(
			"a", {
				onClick: handleHealthReportClick(result.report)
			}, "Click here to view"
		), undefined, undefined, undefined, `health_report_${result.report}`);

		store.dispatch(addReportNotification(result.report));
		store.dispatch(removeRunningHealthReport(result.report));
	}
}

export const scheduleUpdatingCallback = (result: any) => {
	const response = JSON.parse(result);

	const modalState = getState().UI.deviceHealthModalState[response.device];
	if (modalState && modalState.adsUpdating && modalState.adsUpdating.value !== true) {
		return;
	}

	store.dispatch(successNotification("Device Updating", "Device is updating ads"));
}

export const adAnalyticsExportReadyCallback = (result: AdAnalyticsReadyArgs) => {
	const { ad, url, user, sizes, stores, startDate, endDate } = result;
	const state = getState();
	if (getActiveUserUuid(state) !== user) {
		return;
	}

	store.dispatch(setAnalyticsReportPending(ad, false));
	const { name } = getAdById(state, ad);
	const { 1: token } = url.split("/export/");
	const sizesListArray = typeof sizes === "string" ? [ sizes ] : sizes;
	const storesListArray = typeof stores === "string" ? [ stores ] : stores;
	const storesList = storesListArray.length
		?  storesListArray.map((s) => (getStoreById(state, s) || { name: "" }).name).join(", ")
		: [ "All" ];
	const sizesList = sizesListArray.length ? sizesListArray.join(", ") : [ "All" ];
	const createLi = (content) => createElement("li", {}, content);
	const createUl = (children) => createElement("ul", {}, ...children);
	const a = createElement("a", {
		onClick: getAnalyticsReportDownloadAsync(ad, token, name),
		style: { cursor: "pointer" }
	}, "Click here to download");
	const list = createUl([
		createLi(`Start Date: ${ startDate }`),
		createLi(`End Date: ${ endDate }`),
		createLi(`Sizes: ${ sizesList }`),
		createLi(`Stores: ${ storesList }`)
	]);

	Notifications.plain(`Analytics Report Ready for ${ name }`, createElement("div", {}, list, a ), null, 0);
}

const cacheClearedCallback = (result: any) => {
	const response = JSON.parse(result);

	const modalState = getState().UI.deviceHealthModalState[response.device];
	if (modalState && !modalState.clearingCache) {
		return;
	}

	store.dispatch(successNotification("Cache Cleared", "Device cache successfully cleared"));
	store.dispatch(setDeviceClearCacheStatus(response.device, false));
}

export const scheduleUpdatedCallback = (result: any) => {
	const response = JSON.parse(result);

	const modalState = getState().UI.deviceHealthModalState[response.device];
	if (modalState && modalState.adsUpdating && modalState.adsUpdating.value !== true) {
		return;
	}

	if (response.success) {
		store.dispatch(successNotification("Device Updating", "Device successfully updated ads"));
		store.dispatch(setDeviceUpdatingAds(response.device, false));
		// We also need to clear the state of the device clear cache here
		// This can be removed once CON-2525 is resolved
		store.dispatch(setDeviceClearCacheStatus(response.device, false));
	} else {
		store.dispatch(errorNotification("Device Updating", "Device failed to update ads"));
		store.dispatch(setDeviceUpdatingAds(response.device, false));
		// We also need to clear the state of the device clear cache here
		// This can be removed once CON-2525 is resolved
		store.dispatch(setDeviceClearCacheStatus(response.device, false));
	}
}

export const rebootIniatedCallback = (result: any) => {
	store.dispatch(successNotification("Device Rebooting", "Device has begun rebooting"));
}

export const rebootCompletedCallback = (result: any) => {
	const response = JSON.parse(result);
	store.dispatch(successNotification("Device Rebooted", "Device has successfully rebooted"));
	store.dispatch(setDeviceRebooting(response.device, false));
}

export const devicePongCallback = (result: any) => {
	const { device: deviceUUID, maintenanceMode } = JSON.parse(result);
	const devices = getAllDevices(getState());
	const device = getDeviceByUUID(devices, deviceUUID);

	if (device) {
		const newHeartbeat = Utils.getISOTimestamp();
		const updatedDevice = update(device, {
			status: {
				heartbeat: {
					$set: newHeartbeat
				}
			},
			maintenanceMode: {
				$set: maintenanceMode
			}
		});

		store.dispatch(updateDevice(updatedDevice));
		store.dispatch(setDeviceLastSeen(deviceUUID, newHeartbeat));
	}
}

export const permissionsChangedCallback = (result: PermissionsChangedArgs) => {
	const companyId = getActiveCompanyId(getState());

	// get updated user permissions (comes with company info)
	store.dispatch(getCompanyInfoAsync(companyId));
	// get updated custom permissions
	store.dispatch(fetchPermissionSettings());
};

export const deviceAssociatedCallback = (result: DeviceUUIDArgs) => {
	store.dispatch(fetchUpdatedDeviceAsync(result.device));
}

export const deviceReleasedCallback = (result: DeviceUUIDArgs) => {
	const { device: deviceId } = result;
	const device = getDeviceById(getState(), deviceId);

	if (device) {
		store.dispatch(deleteDevice(device, device.company));
	}
}

export const softwareUpdatingCallback = (result: DeviceUUIDArgs) => {
	store.dispatch(successNotification("Device Software Update", "Device software is updating"));
}

export const softwareUpdatedCallback = (result: string) => {
	const { device: deviceId, version} = JSON.parse(result);
	const device = getDeviceById(getState(), deviceId);
	store.dispatch(successNotification("Device Software Update", "Device software has finished updating"));
	store.dispatch(setDeviceSoftwareUpdating(deviceId, false));

	const updatedDevice = update(device, {
		softwareVersion: {
			$set: version
		}
	});

	store.dispatch(updateDevice(updatedDevice));
}

export const updateStoreCallback = (result: UpdateStoreArgs) => {
	store.dispatch(updateStore(result.store));
}

export const maintenanceModeChangedCallback = (result: string) => {
	const { device: deviceUuid, enabled } = JSON.parse(result);
	const device = getDeviceById(getState(), deviceUuid);

	const updatedDevice = update(device, {
		maintenanceMode: {
			$set: enabled
		}
	});

	store.dispatch(updateDevice(updatedDevice));
}

/*
 * Pusher Event Binding Definitions
 */
const eventBindings = [
	{
		event: EVENTS.media,
		callback: mediaChangedCallback
	},
	{
		event: EVENTS.adThumbnailUpdated,
		callback: adThumbnailUpdatedCallback
	},
	{
		event: EVENTS.adAnalyticsExportReady,
		callback: adAnalyticsExportReadyCallback
	},
	{
		event: EVENTS.screenshotReceived,
		callback: screenshotReceivedCallback
	},
	{
		event: EVENTS.snapshotRefreshed,
		callback: snapshotRefreshedCallback
	},
	{
		event: EVENTS.dbUploadStarted,
		callback: dbUploadStartedCallback
	},
	{
		event: EVENTS.dbUploadCompleted,
		callback: dbUploadCompletedCallback
	},
	{
		event: EVENTS.reportResultCompleted,
		callback: reportResultCompletedCallback
	},
	{
		event: EVENTS.scheduleUpdating,
		callback: scheduleUpdatingCallback
	},
	{
		event: EVENTS.scheduleUpdated,
		callback: scheduleUpdatedCallback
	},
	{
		event: EVENTS.rebootInitiated,
		callback: rebootIniatedCallback
	},
	{
		event: EVENTS.rebootCompleted,
		callback: rebootCompletedCallback
	},
	{
		event: EVENTS.cacheCleared,
		callback: cacheClearedCallback
	},
	{
		event: EVENTS.devicePong,
		callback: devicePongCallback
	},
	{
		event: EVENTS.speedTestStarted,
		callback: speedTestProgressCallback
	},
	{
		event: EVENTS.speedTestProgress,
		callback: speedTestProgressCallback
	},
	{
		event: EVENTS.speedTestCompleted,
		callback: speedTestProgressCallback
	},
	{
		event: EVENTS.speedTestFailed,
		callback: speedTestFailedCallback
	},
	{
		event: EVENTS.permissionsChanged,
		callback: permissionsChangedCallback
	},
	{
		event: EVENTS.deviceAssociated,
		callback: deviceAssociatedCallback
	},
	{
		event: EVENTS.deviceReleased,
		callback: deviceReleasedCallback
	},
	{
		event: EVENTS.softwareUpdating,
		callback: softwareUpdatingCallback
	},
	{
		event: EVENTS.softwareUpdated,
		callback: softwareUpdatedCallback
	},
	{
		event: EVENTS.updateStore,
		callback: updateStoreCallback
	},
	{
		event: EVENTS.maintenanceModeChanged,
		callback: maintenanceModeChangedCallback
	}
] as EventBinding[];

export class PusherConnection {
	pusherConnection: Pusher.Pusher;
	companyChannel: Pusher.Channel;

	initializeConnection() {
		const { user } = getState().User;
		const pusherCredentials = getPusherCredentials(getState());

		if (user && !this.pusherConnection) {
			this.pusherConnection = new Pusher(pusherCredentials.key, {
				authEndpoint: "",
				cluster: pusherCredentials.cluster,
				encrypted: true
			});

			this.rebindEvents();
		}
	}

	rebindEvents() {
		const activeCompany = getActiveCompany(getState());

		if (this.pusherConnection === null || this.pusherConnection === undefined) {
			this.initializeConnection();
		}

		if (activeCompany) {
			// Unbind all current company events
			eventBindings.map((binding: EventBinding) => {
				if (this.companyChannel) {
					this.companyChannel.unbind(binding.event, binding.callback);
				}
			});
			// Set new company channel
			this.companyChannel = this.pusherConnection.subscribe("company-" + activeCompany.uuid);
			// Bind new company channel events
			eventBindings.map((binding: EventBinding) => {
				this.companyChannel.bind(binding.event, binding.callback);
			});
		}
	}

	disconnect() {
		this.pusherConnection.disconnect();
	}
}

export const pusherConnection = new PusherConnection();