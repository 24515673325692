import { createSelector } from "reselect";

import { AdMediaLink, IAd, IBaseComponent, IMedia } from "@connect/Interfaces";
import { AdBuilderState } from "Data/Objects/AppState";
import { getAdBuilderState } from "Data/Selectors/UI";
import { identityReducer } from "Data/Utils";
import { getMediasFromIds, getAllMedia } from "Data/Selectors/Media";

export function getAd(state: AdBuilderState): IAd | null {
	if (state && state.activeAd) {
		return state.activeAd;
	}

	return null;
}

export function getComponentIndex(state: AdBuilderState): number {
	return state.selectedComponentIndex;
}

export function getComponent(ad: IAd, index: number) {
	if (ad && ad.layout && ad.layout.components && ad.layout.components.length > index) {
		return ad.layout.components[index];
	}

	return null;
}

export function getComponentMedia(ad: IAd, selectedComponent: IBaseComponent) {
	return ad.layout.media.filter((mediaLink: AdMediaLink) => {
		return mediaLink.layoutPosition === selectedComponent.id;
	});
}

export function getComponentsFromAd(ad: IAd) {
	return ad ? ad.layout.components.filter((c) => c.id) : [];
}

export function getComponentsHeight(components: IBaseComponent[]) {
	return components.length
		? components.map(({ height }, index) => height.value)
			.reduce((prev, cur) => prev + cur, 0)
		: 0;
}

export function getCurrentSlideshowSlide(state: AdBuilderState): number {
	return state.slideshowCurrentSlide;
}

export function getIdsFromMedia(ad: IAd, component: IBaseComponent) {
	return ad.layout.media
		.filter((aml) => aml && aml.layoutPosition === component?.id)
		.map((m) => m.mediaId);
}

export function getAdMediaIds(ad: IAd) {
	return ad.layout.media.map((mediaLink) => mediaLink.mediaId);
}

export function getLivePreviewValue(state: AdBuilderState): boolean {
	return state.livePreview;
}

export function getMediaFromAd(ad: IAd) {
	return ad.layout.media;
}

export function getResizing(state: AdBuilderState, index: number): boolean {
	return state.componentIsResizing[index];
}

export function getVideoDuration(media: IMedia[]): number {
	return media.find((medium) => medium.mediaType === "video")?.duration ?? 0;
}

export const getActiveAd = createSelector(
	[ getAdBuilderState ],
	getAd
);

export const getAdComponents = createSelector(
	[ getActiveAd ],
	getComponentsFromAd
);

export const getAdComponentsHeight = createSelector(
	[ getAdComponents ],
	getComponentsHeight
);

export const getAdMedia = createSelector(
	[ getActiveAd ],
	getMediaFromAd
);

export const getComponentByIndex = createSelector(
	[ getActiveAd, identityReducer ],
	getComponent
);

export const getIsResizing = createSelector(
	[ getAdBuilderState, identityReducer ],
	getResizing
);

export const getLivePreview = createSelector(
	[ getAdBuilderState ],
	getLivePreviewValue
);

export const getSelectedComponentIndex = createSelector(
	[ getAdBuilderState ],
	getComponentIndex
);

export const getCurrentSlide = createSelector(
	[ getAdBuilderState ],
	getCurrentSlideshowSlide
);

export const getSelectedComponent = createSelector(
	[ getActiveAd, getSelectedComponentIndex ],
	getComponent
)

export const getSelectedComponentMedia = createSelector(
	[ getActiveAd, getSelectedComponent ],
	getComponentMedia
);

export const getMediaIds = createSelector(
	[ getActiveAd, getSelectedComponent ],
	getIdsFromMedia
);

export const getAllMediaIds = createSelector(
	[ getActiveAd ],
	getAdMediaIds
);

export const getMediaByIds = createSelector(
	[ getAllMedia, getAllMediaIds ],
	getMediasFromIds
);

export const getAdVideoDuration = createSelector(
	[ getMediaByIds ],
	getVideoDuration
);
